/* Slider start */
.slider-home {
  margin-top: 20px;
}

.w-100 {
  width: 100%;

  @media (min-width: 768px) {
    width: 500px;
  }
}

.blank {
  height: 1000px;
  margin-bottom: -100px;
}

.slider-home {
  margin-top: 25px;
}

.slider-home h2 {
  font-weight: 600;
}

.carousel-item {
  height: 29rem;
  min-height: 300px;
}

.carousel-item::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: 0;
}

.carousel-caption {
  bottom: 100px !important;
}

.carousel-caption h5 {
  font-size: 2rem;
  font-weight: 700px;
}

.carousel-caption p {
  font-size: 1rem;
}

.slider-btn {
  margin-top: 30px;
}

.slider-btn .btn-1 {
  background-color: #0072ea;
  color: white;
  border-radius: 50px;
  padding: 0.5rem 2rem;
  font-size: 1rem;
}

.slider-btn .btn-1:hover {
  color: #0072ea;
  font-weight: 500;
  background-color: white;
}

.slider-btn .btn-2 {
  background-color: #0072ea;
  color: white;
  border-radius: 50px;
  padding: 0.5rem 2rem;
  font-size: 1rem;
  margin-left: 10px;
}

.slider-btn .btn-2:hover {
  color: #0072ea;
  font-weight: 500;
  background-color: white;
}

@media screen and (min-width: 481px) and (max-width: 560px) {
  .carousel-item {
    height: 20rem !important;
  }

  .carousel-caption {
    bottom: 0px !important;
    top: 20px !important;
  }

  .slider-btn {
    margin-top: 0px;
  }

  .slider-btn .btn-1 {
    background-color: #0072ea;
    color: white;
    border-radius: 50px;
    padding: 0.5rem 2rem;
    font-size: 0.6rem;
  }

  .slider-btn .btn-2 {
    background-color: #0072ea;
    color: white;
    border-radius: 50px;
    padding: 0.5rem 2rem;
    font-size: 0.6rem;
    margin-left: 10px;
  }
}

@media screen and (min-width: 450px) and (max-width: 480px) {
  .carousel-item {
    height: 0rem !important;
  }

  .carousel-caption {
    bottom: 0px !important;
    top: 20px !important;
  }

  .slider-btn .btn-1 {
    background-color: #0072ea;
    color: white;
    border-radius: 50px;
    padding: 0.5rem 2rem;
    font-size: 0.6rem;
  }

  .slider-btn .btn-2 {
    background-color: #0072ea;
    color: white;
    border-radius: 50px;
    padding: 0.5rem 2rem;
    font-size: 0.6rem;
    margin-left: 10px;
  }

  .carousel-caption p {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 561px) and (max-width: 680px) {
  .carousel-item {
    height: 23rem !important;
  }

  .carousel-caption {
    bottom: 0px !important;
    top: 20px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .carousel-item {
    height: 1rem !important;
  }

  .carousel-caption {
    bottom: 0px !important;
    top: 20px !important;
  }

  .carousel-item {
    min-height: 210px;
  }

  .carousel-caption p {
    font-size: 0.6rem;
  }

  .slider-home h2 {
    font-weight: 600;
    font-size: 15px;
  }

  .slider-btn .btn-1 {
    background-color: #0072ea;
    color: white;
    border-radius: 50px;
    padding: 0.3rem 0.8rem;
    font-size: 0.5rem;
    margin-top: -50px;
  }

  .slider-btn .btn-2 {
    background-color: #0072ea;
    color: white;
    border-radius: 50px;
    padding: 0.3rem 0.8rem;
    font-size: 0.5rem;
    margin-left: 10px;
    margin-top: -50px;
  }

  .carousel-control-prev-icon {
    width: 22px;
  }

  .carousel-control-next-icon {
    width: 22px;
  }

  .carousel-indicators {
    margin-bottom: 0.4rem;
  }

  .carousel-indicators [data-bs-target] {
    width: 20px;
    height: 2px;
  }
}

@media screen and (min-width: 375px) and (max-width: 450px) {
  .carousel-item {
    height: 1rem !important;
  }

  .carousel-caption {
    bottom: 0px !important;
    top: 20px !important;
  }

  .carousel-item {
    min-height: 210px;
  }

  .carousel-caption p {
    font-size: 0.6rem;
  }

  .slider-home h2 {
    font-weight: 600;
    font-size: 16px;
  }

  .slider-btn .btn-1 {
    background-color: #0072ea;
    color: white;
    border-radius: 50px;
    padding: 0.4rem 0.8rem;
    font-size: 0.5rem;
    margin-top: -36px;
  }

  .slider-btn .btn-2 {
    background-color: #0072ea;
    color: white;
    border-radius: 50px;
    padding: 0.4rem 0.8rem;
    font-size: 0.5rem;
    margin-left: 10px;
    margin-top: -36px;
  }

  .carousel-control-prev-icon {
    width: 25px;
  }

  .carousel-control-next-icon {
    width: 25px;
  }

  .carousel-indicators {
    margin-bottom: 0.4rem;
  }

  .carousel-indicators [data-bs-target] {
    width: 20px;
    height: 2px;
  }
}

@media (max-width: 1000px) {
  .slider-home {
    margin-top: 10px;
  }
}

@media screen and (min-width: 1441px) and (max-width: 2560px) {
  .carousel-caption {
    bottom: 40 !important;
  }

  .carousel-caption p {
    font-size: 1.5rem;
  }

  .slider-home h2 {
    font-weight: 600;
    font-size: 3rem;
  }

  .slider-btn .btn-2 {
    background-color: #0072ea;
    color: white;
    border-radius: 50px;
    padding: 0.7rem 1.4rem;
    font-size: 1.5rem;
    margin-left: 10px;
  }

  .slider-btn .btn-1 {
    background-color: #0072ea;
    color: white;
    border-radius: 50px;
    padding: 0.7rem 1.4rem;
    font-size: 1.5rem;
  }

  .carousel-item {
    height: 40rem;
    min-height: 300px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .carousel-item {
    height: 30rem;
    min-height: 300px;
  }
}

/* Slider end */